import axios from "axios";
import { isBefore } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import CONFIG from "../config";
import { createYoutubeEmbedLink } from "../utils/video.utils";

const formatNumber = (number) => {
  const withDecimals = number.toFixed(2);
  const decimals = withDecimals.substr(-3).replace(".", ",");
  let wholes = withDecimals.substr(0, withDecimals.length - 3);
  if (wholes.length > 3) {
    switch (wholes.length) {
      case 4:
      case 7:
        wholes = [wholes.slice(0, 1), ".", wholes.slice(1)].join("");
        break;
      case 5:
      case 8:
        wholes = [wholes.slice(0, 2), ".", wholes.slice(2)].join("");
        break;
      case 6:
      case 9:
        wholes = [wholes.slice(0, 3), ".", wholes.slice(3)].join("");
        break;
      default:
        wholes = [wholes.slice(0, 1), ".", wholes.slice(1)].join("");
    }
  }

  return wholes + decimals;
};

const formatPrice = (price) => {
  return parseFloat((`${price}` || "0").replace(",", "."));
};

export const getCars = (locale) =>
  axios
    .get(`${CONFIG.API_BASE}/cars`, {
      params: { locale, v: CONFIG.VERSION },
    })
    .then((res) => res.data)
    .then((res) => {
      let cars = res.data
        .map((car) => {
          if (car.product_video) {
            car.product_video = createYoutubeEmbedLink(car.product_video);
          }

          // const TAEG = car.slug.includes('sportage') ? CONFIG.CAR_TAEG_ALT : CONFIG.CAR_TAEG;
          // const INTREST = car.slug.includes('sportage') ? CONFIG.CAR_INTREST_ALT : CONFIG.CAR_INTREST;

          // make prices numbers again
          car.price = formatPrice(car.price);
          car.price_discount = formatPrice(car.price_discount);
          car.price_net = formatPrice(car.price_net);
          car.price_trade_bonus = formatPrice(car.price_trade_bonus);
          car.price_motor_show = formatPrice(car.price_motor_show);
          car.cash_price = formatPrice(car.cash_price);
          car.credit_amount = formatPrice(car.credit_amount);
          car.down_payment = formatPrice(car.down_payment);
          car.last_monthly_payment_amount = formatPrice(
            car.last_monthly_payment_amount
          );
          car.monthly_payments_amount = formatPrice(
            car.monthly_payments_amount
          );
          car.price_easymove = formatPrice(car.price_easymove);
          car.total_amount = formatPrice(car.total_amount);

          car.price_default = formatPrice(car.price_default);
          car.price_default_discount = formatPrice(car.price_default_discount);
          car.price_default_net = formatPrice(car.price_default_net);
          car.price_default_trade_bonus = formatPrice(
            car.price_default_trade_bonus
          );
          car.price_default_motor_show = formatPrice(
            car.price_default_motor_show
          );

          car.amount_cash = formatPrice(car.amount_cash);
          car.amount_deposit = formatPrice(car.amount_deposit);
          car.amount_loan = formatPrice(car.amount_loan);
          car.amount_last_payment = formatPrice(car.amount_last_payment);
          car.amount_total = formatPrice(car.amount_total);

          const timeZone = 'Europe/Brussels'
          const now = zonedTimeToUtc(new Date(), timeZone)
          const hideBadgeFrom = zonedTimeToUtc('2023-01-01 00:00:00', timeZone)
          car.badge = isBefore(now, hideBadgeFrom) && car.price_default_short_name.toLowerCase() === "ev6";

          // select localized content for engines
          car.engine = car.engine.reduce((engines, engine) => {
            const amount_last_payment = engine.amount_last_payment || {};

            engine.amount_cash = formatPrice(engine.amount_cash[locale]);
            engine.amount_deposit = formatPrice(engine.amount_deposit[locale]);
            engine.amount_loan = formatPrice(engine.amount_loan[locale]);
            engine.amount_term = formatPrice(engine.amount_term[locale]);
            engine.amount_last_payment = formatPrice(
              amount_last_payment[locale]
            );
            engine.amount_total = formatPrice(engine.amount_total[locale]);

            if (engine.description) {
              engine.description = engine.description[locale];
            }
            engine.easymove = formatPrice(engine.easymove[locale]);
            engine.kia_private_lease = formatPrice(engine?.kia_private_lease?.[locale]);
            engine.kia_lease = formatPrice(engine?.kia_lease?.[locale]);
            engine.financial_renting = formatPrice(
              engine?.financial_renting?.[locale]
            );
            engine.power = engine.power[locale];
            engine.price = formatPrice(engine.price[locale]);
            engine.price_calculator = engine.price_calculator[locale];
            engine.price_discount = formatPrice(engine.price_discount[locale]);
            engine.price_motor_show = formatPrice(
              engine.price_motor_show[locale]
            );
            engine.price_net = formatPrice(engine.price_net[locale]);
            engine.price_trade_bonus = formatPrice(
              engine.price_trade_bonus[locale]
            );
            engine.status = engine.status[locale];
            if (engine.promo_information) {
              engine.promo_information = engine.promo_information[locale] || "";
            }

            engine.amount_jkp = engine.jkp[locale];
            engine.amount_intrest = engine.annual_debit_interest[locale];
            engine.amount_terms = engine.amount_term;
            engine.co_two_emissions = engine.co_two_emissions?.[locale];
            engine.fuel_consumption = engine.fuel_consumption?.[locale];

            if (locale !== "fr-lu") {
              engine.nedc = "";
            }

            // todo: remove when BE solution is added
            engine.highlight = engine.hasOwnProperty('highlight') ? engine.highlight : engine.title.toLowerCase().includes('inspire') || engine.title.toLowerCase().endsWith('business') || engine.title.toLowerCase().endsWith('business plus') || engine.title.toLowerCase().includes('style edition') || engine.title.toLowerCase().includes('sportage style');
            engine.inspire = engine.highlight && engine.title.toLowerCase().includes('inspire');
            engine.style = engine.highlight && (engine.title.toLowerCase().includes('style edition') || engine.title.toLowerCase().includes('sportage style'));

            engine.badge = null;

            if (engine.inspire) {
              engine.badge = 'inspire';
            }
            if (engine.style) {
              engine.badge = 'style';
            }

            engines.push({
              ...engine,
            });

            return engines;
          }, []);

          const selectedEngine =
            car.engine.find((engine) => engine.is_exposed_car[locale] === 1) || {};

          car.amount_jkp = selectedEngine.amount_jkp;
          car.amount_intrest = selectedEngine.amount_intrest;
          car.amount_terms = selectedEngine.amount_term;
          car.co_two_emissions = selectedEngine.co_two_emissions;
          car.fuel_consumption = selectedEngine.fuel_consumption;

          car.engine_types = JSON.parse(car.powertrains || "[]");

          // select localized content for media
          car.media = car.media.reduce((medias, cmedia) => {
            const body = cmedia.body ? cmedia.body[locale] : "";
            const media = cmedia.media ? cmedia.media[locale] : "";
            let link = cmedia.link ? cmedia.link[locale] : "";
            let videoId = "";

            if (link) {
              const ytlink = createYoutubeEmbedLink(link);

              if (ytlink) {
                link = ytlink.link;
                videoId = ytlink.id;
              } else {
                link = '';
                videoId = '';
              }
            }

            if (media || link) {
              medias.push({
                id: cmedia.id,
                title: cmedia.title,
                body,
                media,
                link,
                type: cmedia.type,
                weight: cmedia.weight,
                videoId,
              });
            }

            return medias;
          }, []);

          // representative example variable replacement
          const replacements = [
            "credit_amount",
            "down_payment",
            "cash_price",
            "annual_percentage_rate",
            "fixed_annual_borrowing_rate",
            "credit_period",
            "credit_period_repayable_in",
            "monthly_payments_amount",
            "last_monthly_payment_amount",
            "action_valid_from",
            "action_valid_to",
            "total_amount",
          ];

          const amountVariables = [
            "credit_amount",
            "down_payment",
            "cash_price",
            "monthly_payments_amount",
            "last_monthly_payment_amount",
            "total_amount",
          ];

          replacements.forEach((needle) => {
            let value = car[needle];
            if (amountVariables.includes(needle)) {
              let newValue = value;
              if (typeof newValue === "string") {
                newValue = parseInt(newValue, 10);
              }
              if (!isNaN(newValue) && typeof newValue === "number") {
                value = formatNumber(newValue);
              }
            }
            car.representative_example = car.representative_example.replace(
              `{{${needle}}}`,
              value
            );
          });

          return car;
        })
        .filter((car) => car.hotspot.length > 0);

      cars.sort((a, b) => {
        if (a.hotspot[0].id > b.hotspot[0].id) {
          return 1;
        }
        if (a.hotspot[0].id < b.hotspot[0].id) {
          return -1;
        }
        return 0;
      });

      // if (!cars.filter(car => car.price_default_short_name === 'EV6' && car.status === 1).length) {
      //   // add temporary custom reserve now hotspot for EV6 if not exists
      //   cars = [
      //     {
      //       id: 0,
      //       title: 'EV6',
      //       price_default_short_name: 'EV6',
      //       thumbnail: '',
      //       engine_types: ['ev'],
      //       slug: '',
      //       is_electric: 1,
      //       status: 1,
      //       hotspot: [
      //         {
      //           yaw: '2.883142893966671',
      //           pitch: '0.05805518534746845',
      //           rotation: '0',
      //         }
      //       ],
      //       cta: {
      //         'nl-be': 'https://www.kia.com/be/nl/modellen/ev6/ontdekken/',
      //         'fr-be': 'https://www.kia.com/be/fr/modeles/ev6/decouvrir/',
      //         'fr-lu': 'https://www.kia.com/lu/modeles/ev6/decouvrir/',
      //       },
      //       price: 43990
      //     },
      //     ...cars,
      //   ];
      // }

      return cars;
    });

export const getCar = (locale, id) =>
  axios
    .get(`${CONFIG.API_BASE}/cars/${id}`, {
      params: { locale },
    })
    .then((res) => res.data)
    .then((res) => res.data);
