import './cardetails.scss';

import { FormattedMessage, useIntl } from 'react-intl';
import React, { useContext } from 'react';

import Box from '../../02_molecules/box/Box';
import Button from '../../01_atoms/button/Button';
import CONFIG from '../../../common/config';
import Icon from '../../01_atoms/icons/Icon';
import { KiaContext } from '../../../common/context/main.context';
import PriceInfo from '../priceinfo/Priceinfo';
import PriceinfoLegal from '../priceinfoLegal/PriceinfoLegal';
import PriceinfoPrice from '../priceinfoPrice/PriceinfoPrice';
import { formatPrice } from '../../../common/utils/price.utils';

export default function CarDetails({ car, children, back, showMore, showTop }) {
  const {
    mobile,
    currentLocale,
    showMonthlyPrices,
    isB2c
  } = useContext(KiaContext);

  return (
    <>
      <div className={`car__details--back${ showTop ? ' blend' : ''}${ showMore ? '__configurator' : ''}`}>
        <Button mode="naked" color="light" onClick={() => back()} icon={<Icon icon="arrow-left" />} className="button--back" size="small"><FormattedMessage id="back.back" /></Button>
      </div>
      <div className="car__details">
        <Box className="car__pricing">
          { showMore ? (<h4 className="heading heading--4 fc--primary">{ car.price_default_short_name }</h4>) : null }
          { !mobile && <CarDetailsPrice {...{showMore, showMonthlyPrices, car, currentLocale, isB2c}} /> }
        </Box>
        { children }
      </div>
    </>
  );
}

const CarDetailsPrice = ({showMore, showMonthlyPrices, car, currentLocale, isB2c}) => {
  return <div className="car__pricing__wrapper">
    {showMore && isB2c && <PriceLine {...{showMonthlyPrices, car, currentLocale, isB2c, type: 'easymove'}} />}
    {showMore && !isB2c && <>
      <PriceLine {...{showMonthlyPrices, car, currentLocale, isB2c, type: 'financial_renting'}} />
      <PriceLine {...{showMonthlyPrices, car, currentLocale, isB2c, type: 'kia_lease'}} />
    </>}
  </div>
}

const PriceLine = ({showMonthlyPrices, car, currentLocale, isB2c, type}) => {
  
  const intl = useIntl()
  const typeMonth = intl.formatMessage({id: `${type}.month`});

  const price = showMonthlyPrices ?
    formatPrice(car?.[type]?.price, CONFIG.PRICE_FORMAT, currentLocale.locale) :
    formatPrice(car.price_motor_show, CONFIG.PRICE_FORMAT, currentLocale.locale)
  const hasPrice = Number(price.replace(/\D+/g, '')) !== 0
  if (!hasPrice) return null

  const engineIsElectrical = car?.[type]?.is_electrical || false
  const messageId = type === 'easymove' ? `priceinfo.legal.${type}` : `priceinfo.legal.${type}.${engineIsElectrical ? 'electric' : 'fuel'}`

  return <div className="car__pricing__left fc--primary">
  <div className="mt--0 mb--0 bold car__pricing__price__name">
    <FormattedMessage id={`${type}.label`} />&nbsp;
  </div>
  <div className="car__pricing__price__info">
    <div className="mt--0 mb--0">
      {showMonthlyPrices ? (
        <p className="my--0 car__pricing__price__amount car__pricing__price__amount--monthly">
          <FormattedMessage id={`from`} />&nbsp;
          {price}
          <small className="p--2 mr--2">&nbsp;/&nbsp;<span dangerouslySetInnerHTML={{__html: typeMonth}} /></small>
        </p>
      ) : (
        <p className="my--0 bold car__pricing__price__amount">{price}</p>
      ) }
    </div>
    <div className="car__price ml--2">
      <div className="p--2">
        {showMonthlyPrices ? (
          <React.Fragment>
            <PriceInfo
              title={car.title}
              subtitle={car.power}
              promo={car.price_default_promo_information}
              price={{
                catalog: car.price,
                discount: car.price_discount,
                netto: car.price_net,
                takeover: car.price_trade_bonus,
                salon: car.price_motor_show,
              }}
              actions={(
                <>
                  { car.price_default_calculator_link ? (<Button href={car.price_default_calculator_link} mode="outline" size="small" color="dark" className="mt--2" iconAfter icon={<Icon icon="link" />}><FormattedMessage id="car.fiscalculator" /></Button>) : null }
                </>
              )}
              model={car.slug}
            >
              <PriceinfoPrice {...{isB2c, car, locale: currentLocale.locale, type}} />
              <PriceinfoLegal {...{car, type, locale: currentLocale.locale, messageId}} />
            </PriceInfo>
          </React.Fragment>
        ) : (
          <React.Fragment>
            { formatPrice(car.price_motor_show, CONFIG.PRICE_FORMAT, currentLocale.locale) }
            <PriceInfo
              title={`${car.title} ${car.power || ''}`}
              promo={car.price_promo_information}
              price={{
                catalog: car.price,
                discount: car.price_discount,
                netto: car.price_net,
                takeover: car.price_trade_bonus,
                salon: car.price_motor_show,
              }}
              model={car.slug}
            />
          </React.Fragment>
        ) }
      </div>
    </div>
  </div>
</div>
}